/* eslint-disable */
import axios from 'axios';
import { ACCESS_TOKEN_NAME } from 'containers/MSAuth/MsalAuth';
import { msal } from 'containers/MSAuth/MSAuth';
import qs from 'qs';

const { CancelToken } = axios;

const setAccessTokenToLocalStorage = (accessToken) => {
  localStorage.setItem(ACCESS_TOKEN_NAME, accessToken);
};

axios.interceptors.request.use((config) => config);

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const status = error.response ? error.response.status : null;
    if (status === 401) {
      try {
        const { response: { config } = {} } = error;
        const { accessToken } = await msal.getAccessToken();
        setAccessTokenToLocalStorage(accessToken);
        return axios.request({
          ...config,
          headers: { ...config.headers, Authorization: `Bearer ${accessToken}` },
        })
      } catch ({ errorCode = null }) {
        if (msal.requiresInteraction(errorCode)) {
          msal.acquireTokenRedirect();
          return;
        }
        msal.redirectToLogin();
      }
    }
    if (status === 403) {
      try {
        const noAccessRoute = '/no-access';
        if (window.location.pathname !== noAccessRoute) return window.location.href = noAccessRoute;
      } catch (e) {
        console.error('403', e); // eslint-disable-line
      }
    }

    return Promise.reject(error);
  },
);

const req = ({ url, data, params, method, absoluteUrl, headers, additionalData, responseType }) => {
  const token = localStorage.getItem(ACCESS_TOKEN_NAME);
  const auth = token
    ? {
      Authorization: `Bearer ${token}`,
    }
    : null;

  const apiUrl = process.env.REACT_APP_API_URL || 'https://trips-int.k8s-app-dev.dtek.cloud/api/';

  const basicHeaders = {
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Content-Type': 'application/json',
  };

  return axios({
    method: method ? method : 'get',
    url: absoluteUrl || `${apiUrl}${url}`,
    headers: headers ? { ...auth, ...headers } : { ...auth, ...basicHeaders },
    data,
    params,
    paramsSerializer: params => qs.stringify(params, { indices: false, arrayFormat: 'comma' }),
    ...responseType && { responseType },
    ...additionalData,
    cancelToken: new CancelToken(cancel => {
    })
  });
};

export default req;
